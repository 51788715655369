import React from 'react';
import Logo680 from '../../images/optimized/logos_680_news.svg';
import Logo981 from '../../images/optimized/logos_98.1_chfi.svg';
import LogoCP24 from '../../images/optimized/logos_cp24.svg';
import LogoGoogle from '../../images/optimized/logos_google.svg';
import LogoSN590 from '../../images/optimized/logos_sn590_the_fan.svg';
import { StylableProp } from '../../utils/stylable-prop';
import VisualDesc from '../visual-description';
import VisualTitle from '../visual-title';
import * as style from './index.module.scss';

export default function VisualBanner(props: StylableProp<{}>) {
  // const guyImageResult = useStaticQuery<GuyImageQuery>(dataQuery);
  // const guyImage = getImage(guyImageResult.file as unknown as ImageDataLike);

  return (
    <section
      className={`${style.visualBanner} overflow-hidden position-relative m-auto ${props.className}`}
    >
      <div className="container position-relative h-100 d-flex align-items-center justify-content-center">
        <div className="position-relative h-100 d-flex flex-column align-items-center justify-content-center">
          <div className={style.visualContent + ' text-center'}>
            <VisualTitle className={' mb-3 mb-md-4'}>
              Now more than ever, <br />
              You need a plan.
            </VisualTitle>
            <VisualDesc>
              Everything you need in one place to buy, refinance and save <br /> more money on your
              next mortgage move.
            </VisualDesc>
            <div className="d-md-flex justify-content-center align-items-center">
              <strong className="d-lg-none d-xl-block mx-2">Featured on:</strong>
              <img className={style.svgLogo} src={LogoCP24} />
              <img className={style.svgLogo} src={Logo680} />
              <img className={style.svgLogo} src={LogoSN590} />
              <img className={style.svgLogo} src={Logo981} />
              <img className={style.svgLogo} src={LogoGoogle} />
            </div>
          </div>
        </div>
        {/* {guyImage && (
          <div className={style.guy + ' d-none d-lg-block position-relative'}>
            <GatsbyImage image={guyImage} alt="Guy sitting in char" />
          </div>
        )} */}
      </div>
    </section>
  );
}

// const dataQuery = graphql`
//   query GuyImage {
//     file(relativePath: { eq: "guy-in-chair-recolored.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 400, quality: 95)
//       }
//       relativePath
//     }
//   }
// `;
