import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Logo680 from '../../../../images/optimized/logos_680_news.svg';
import Logo981 from '../../../../images/optimized/logos_98.1_chfi.svg';
import LogoCP24 from '../../../../images/optimized/logos_cp24.svg';
import LogoGoogle from '../../../../images/optimized/logos_google.svg';
import LogoSN590 from '../../../../images/optimized/logos_sn590_the_fan.svg';
import { StylableProp } from '../../../../utils/stylable-prop';
import { FeatureCardsProps } from '../../../feature-cards';
import FeatureCard from '../../../feature-cards/feature-card';
import VisualDesc from '../../../visual-description';
import VisualTitle from '../../../visual-title';
import * as style from './index.module.scss';

export default function VisualBannerMobile(props: StylableProp<FeatureCardsProps>) {
  const cardWidth = 270;
  const cardHeight = 350;
  const gap = 32;
  const cardCount = 4;
  const maxOffset = (cardWidth + gap) * (cardCount - 1) * -1;

  const [focused, setFocused] = useState(0);
  const [cardOffset, setCardOffset] = useState(0);
  const [transClass, setTransClass] = useState(style.trans);

  const getOffset = (index: number) => (cardWidth + gap) * index * -1;

  return (
    <section className={`${style.visualBanner} ${props.className}`}>
      <div className="container d-flex flex-column align-items-center justify-content-center">
        <VisualTitle className={'text-center'} mode="compact">
          Now more than ever, <br />
          You need a plan.
        </VisualTitle>
        <VisualDesc className={style.desc + ' text-center'}>
          Everything you need in one place to buy, refinance and save more money on your next
          mortgage move.
        </VisualDesc>
        <p className="small">
          <b>Featured On:</b>
        </p>
        <div className={style.logos}>
          <img className={`${style.svgLogo} `} src={LogoCP24} />
          <img className={`${style.svgLogo} `} src={Logo680} />
          <img className={`${style.svgLogo} ${style.large}`} src={Logo981} />
          <img className={`${style.svgLogo}`} src={LogoSN590} />
          <img className={`${style.svgLogo}`} src={LogoGoogle} />
        </div>
        <motion.div
          className={`${style.cardsContainer} my-4 align-self-start d-flex align-items-center justify-content-start ${transClass}`}
          onPan={(e, panInfo) => {
            let val = cardOffset - panInfo.delta.x * -1;

            setCardOffset(val);
          }}
          onPanStart={() => setTransClass('')}
          onPanEnd={(e, info) => {
            setTransClass(style.trans);

            let cur = focused;

            if (Math.abs(info.velocity.x * 2) < Math.abs(info.velocity.y)) {
              setCardOffset(getOffset(cur));
              return;
            }

            if (info.velocity.x < 0) {
              cur++;
            } else if (info.velocity.x > 0) {
              cur--;
            }

            if (cur < 0) {
              cur = 0;
            } else if (cur > cardCount - 1) {
              cur = cardCount - 1;
            }

            let offset = getOffset(cur);

            if (offset > 0) {
              offset = 0;
            } else if (offset < maxOffset) {
              offset = maxOffset;
            }

            setFocused(cur);
            setCardOffset(offset);
          }}
          style={{
            translateX: cardOffset
          }}
        >
          <FeatureCard
            className={style.card}
            width={cardWidth}
            height={cardHeight}
            href="/buy"
            title="Purchase."
            caption="Today's Rate"
            value={props.buyingRate}
            description="Buying my Home"
            buttonLabel="Buy my Home"
          />
          <FeatureCard
            className={style.card}
            width={cardWidth}
            height={cardHeight}
            href="/refinance"
            title="Refinance."
            caption="HomeBoost Plan"
            value={props.refinanceRate}
            description="Lower Rate, Bigger Savings"
            buttonLabel="Boost My Savings"
          />
          <FeatureCard
            className={style.card}
            width={cardWidth}
            height={cardHeight}
            href="/line"
            title="Line of Credit."
            caption="HomeBoost Line"
            value={props.lineRate}
            description="Unlock the equity in your home"
            buttonLabel="Save More Money"
          />
          <FeatureCard
            className={style.card}
            width={cardWidth}
            height={cardHeight}
            href="/loan"
            title="Home Equity Loan."
            caption="Homeowner Plan"
            value={props.loanRate}
            description="Get lower rate & save more"
            buttonLabel="Unlock My Equity"
          />
        </motion.div>
      </div>
    </section>
  );
}
