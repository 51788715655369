import loadable from '@loadable/component';
import React, { useState } from 'react';
import { CallToActionCardProps } from '../components/call-to-action/models';
import Layout from '../components/layout';
import VisualBannerMobile from '../components/sections/home/visual-banner-mobile';
import VisualBanner from '../components/visual-banner';
import { RootState } from '../utils/root-state';

const CallToAction = loadable(() => import('../components/call-to-action/index'));
const BuyingNew = loadable(() => import('../components/sections/home/buying-new'));
const EquityIntoCash = loadable(() => import('../components/sections/home/equity-into-cash'));
const KissDebtGoodbye = loadable(() => import('../components/sections/home/kiss-debt-goodbye'));
const ReadyToRenovate = loadable(() => import('../components/sections/home/ready-to-renovate'));
const Refinance = loadable(() => import('../components/sections/shared/refinance'));
const Reviews = loadable(() => import('../components/sections/shared/reviews'));
const FeatureCards = loadable(() => import('../components/feature-cards'));
const Testimonials = loadable(() => import('../components/sections/home/testimonials'));

export default function IndexPage() {
  const callToActionCards: CallToActionCardProps[] = [
    {
      title: 'Finance my home.',
      actionLabel: 'Finance',
      actionHref: '/buy'
    },
    {
      title: 'Refinance my home.',
      actionLabel: 'Refinance',
      actionHref: '/refinance'
    },
    {
      title: 'Add a line of credit.',
      actionLabel: 'Home line of credit',
      actionHref: '/line'
    },
    {
      title: 'Add a home equity loan.',
      actionLabel: 'Home equity loan',
      actionHref: '/loan'
    },
    {
      title: 'Match me with an agent.',
      actionLabel: 'Real Estate',
      actionHref: 'https://apply.nuborrow.com'
    },
    {
      title: 'What should I do?',
      actionLabel: 'Get home advice',
      actionHref: '/learn'
    }
  ];

  const [buyingRate, setBuyingRate] = useState(0);
  const [refinanceRate, setRefinanceRate] = useState(0);
  const [lineRate, setLineRate] = useState(0);
  const [loanRate, setLoanRate] = useState(0);

  const handleState = (state: RootState) => {
    setBuyingRate(state.findLowestRateForProduct('Buying')?.variable_rate ?? 0);
    setRefinanceRate(state.findLowestRateForProduct('Refinancing')?.variable_rate ?? 0);
    setLineRate(state.findLowestRateForProduct('Equity Line')?.rate ?? 0);
    setLoanRate(state.findLowestRateForProduct('Equity Loan')?.rate ?? 0);
  };

  return (
    <Layout pageTitle={'Mortgages Made Easy'} onRootState={handleState}>
      <VisualBanner className={'d-none d-md-block'} />
      <FeatureCards
        className={'d-none d-md-block'}
        buyingRate={buyingRate}
        refinanceRate={refinanceRate}
        lineRate={lineRate}
        loanRate={loanRate}
      />
      <VisualBannerMobile
        className={'d-flex flex-column d-md-none'}
        buyingRate={buyingRate}
        refinanceRate={refinanceRate}
        lineRate={lineRate}
        loanRate={loanRate}
      />
      <Testimonials />
      <KissDebtGoodbye />
      <Refinance title="Get bigger savings." fullLength={true} />
      <ReadyToRenovate />
      <Reviews />
      <BuyingNew buyingRate={buyingRate} />
      <EquityIntoCash />
      <CallToAction title="Put more money in your pocket with Nuborrow" cards={callToActionCards} />
    </Layout>
  );
}
