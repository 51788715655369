import { navigate } from 'gatsby-link';
import React from 'react';
import { StylableProp } from '../../../utils/stylable-prop';
import AnimatedCounter from '../../animated-counter';
import RoundBtn from '../../round-btn';
import { CardProps } from './card-props';
import * as style from './index.module.scss';

export default function FeatureCard(props: StylableProp<CardProps>) {
  return (
    <div
      className={`${style.featureCard} ${props.className} d-flex flex-column justify-content-between`}
      style={{ width: props.width, height: props.height }}
      onClick={() => props.href && navigate(props.href)}
    >
      <div className={style.title}>{props.title}</div>

      <div className={style.valueWrapper + ' text-center'}>
        <div className={style.caption}>{props.caption}</div>
        <AnimatedCounter
          initialValue={0}
          value={props.value}
          className={style.counter}
          suffix={'%'}
          decimals={2}
        />
        <div className={style.description}>{props.description}</div>
      </div>

      <RoundBtn className={style.actionBtn}>{props.buttonLabel}</RoundBtn>
    </div>
  );
}
